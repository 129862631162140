<template>
    <div>
      <br />
      <h1 class="text-xs-center">Hours Of Operation Templates</h1>
      <v-layout row wrap>
        <v-flex md4 xs12 pa-2>
          <v-btn color="primary" :disabled="apiInProgress || (currentTemplate && !isCurrentTemplateEdited)" @click="createTemplate()"><v-icon>fal fa-plus</v-icon> &nbsp; New Template</v-btn>
          <div :style="$vuetify.breakpoint.smAndDown ? 'max-height: 200px; overflow-y: auto' : ''">
            <v-list two-line>
              <template v-for="(template, index) in templates">
                <v-list-tile
                    :key="template.id"
                    ripple
                    @click="editTemplate(template.id)"
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ template.templateName }}</v-list-tile-title>
                    <v-list-tile-sub-title class="text--primary">{{ template.formattedDateUpdated }}</v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-list-tile-action>
                    <v-btn icon @click.stop="deleteTemplate(template)">
                      <v-icon small>fa fa-trash-alt</v-icon>
                    </v-btn>
                  </v-list-tile-action>
                </v-list-tile>
                <v-divider v-if="index + 1 < templates.length" :key="index" />
              </template>
            </v-list>
          </div>
          <div v-show="!templates.length" class="text-xs-center">
            <p>No available templates</p>
          </div>
        </v-flex>
        <v-flex md8 xs12 pa-2>
          <div class="d-flex row justify-space-between mb-3">
            <v-btn color="primary" :disabled="apiInProgress || !isCurrentTemplateEdited || !currentTemplate" @click="saveTemplate()">Save</v-btn>
            <v-btn color="warning" :disabled="apiInProgress || !currentTemplate" @click="cancelTemplateEdit()">Cancel</v-btn>
          </div>
          <div v-show="!currentTemplate" class="text-xs-center">
            Load or create a new template
          </div>
          <div v-if="currentTemplate">
            <v-text-field mb-2 outline v-model="currentTemplate.templateName" label="Template name" />
            <p class="text-xs-center text--accent-1" v-if="totalTransportsConnectedToTemplate">
              <v-icon small>fas fa-info-circle</v-icon> &nbsp; <strong>Vehicles using template: {{totalTransportsConnectedToTemplate}}</strong>
            </p>
            <hours-of-operation use-default-template-if-new v-model="currentTemplate.hoursOfOperation" />
          </div>
        </v-flex>
      </v-layout>
    </div>
</template>

<script>
import src from './src'
export default src
</script>
