import _cloneDeep from 'lodash.clonedeep'
import _isEqual from 'lodash.isequal'

import {
  addHoursOfOperationTemplate, deleteStSetting,
  getCachedHoursOfOperationTemplates, getHoursOfOperationTemplate,
  updateHoursOfOperationTemplate
} from '@/services/st-settings-service'

import { formatDate, formatTime } from '@smarttransit/common'
import { C_ADD_SITE_ALERT } from '@/utilities/mutation-types'
import { getTotalTransportsWithHoursOfOperationTemplate } from '@/services/transportation-profiles-service'

const defaultCurrentTemplate = {
  templateName: '',
  hoursOfOperation: {}
}

export default {
  name: 'hours-of-operation-templates',
  props: {
    signedInUser: Object
  },
  data () {
    return {
      templates: [],
      apiInProgress: false,
      currentTemplate: null,
      originalCurrentTemplate: null,
      totalTransportsConnectedToTemplate: null
    }
  },
  computed: {
    isCurrentTemplateEdited () {
      return this.currentTemplate && !_isEqual(this.originalCurrentTemplate, this.currentTemplate)
    }
  },
  mounted () {
    this.loadTemplates()
  },
  methods: {
    loadTemplates () {
      return getCachedHoursOfOperationTemplates().then((templates) => {
        this.templates = []
        if (templates) {
          this.templates = templates.map((template) => {
            return {
              ...template,
              formattedDateUpdated: `${formatDate(template.dateUpdated)}, ${formatTime(template.dateUpdated)}`
            }
          })
        }
      }).catch((err) => {
        alert(`Error retrieving templates (please reload the page and try again): ${err && err.error ? err.error.message : (err && err.message ? err.message : 'Unknown error')}`)
      })
    },
    async loadTemplateById (id) {
      try {
        const template = await getHoursOfOperationTemplate(id)
        return template
      } catch (err) {
        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `Error in retrieving template: ${err && err.error && err.error.message ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async editTemplate (id) {
      const [template, totalTransportsConnected] = await Promise.all([
        this.loadTemplateById(id),
        getTotalTransportsWithHoursOfOperationTemplate(id)
      ])

      this.currentTemplate = {
        ...template,
        hoursOfOperation: { hours: template.data }
      }

      this.totalTransportsConnectedToTemplate = totalTransportsConnected.count

      this.originalCurrentTemplate = _cloneDeep(this.currentTemplate)
    },
    createTemplate () {
      if (this.isCurrentTemplateEdited && !confirm('Confirm overwriting the current template')) {
        return
      }

      this.currentTemplate = _cloneDeep(defaultCurrentTemplate)
      this.originalCurrentTemplate = _cloneDeep(this.currentTemplate)
    },
    async saveTemplate () {
      if (!this.currentTemplate.templateName) {
        return alert('Please provide a template name')
      }

      if (!this.currentTemplate.hoursOfOperation || !this.currentTemplate.hoursOfOperation.hours) {
        return alert('Hours data needs to be set')
      }

      this.apiInProgress = true

      try {
        if (this.currentTemplate.id) {
          const updatedTemplate = await updateHoursOfOperationTemplate({
            id: this.currentTemplate.id,
            templateName: this.currentTemplate.templateName,
            data: this.currentTemplate.hoursOfOperation.hours
          })

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Successfully updated template: ${this.currentTemplate.templateName}`,
            type: 'success',
            transient: true
          })

          this.currentTemplate = {
            ...this.currentTemplate,
            ...updatedTemplate
          }

          this.originalCurrentTemplate = _cloneDeep(this.currentTemplate)
        } else {
          await addHoursOfOperationTemplate({
            templateName: this.currentTemplate.templateName,
            data: this.currentTemplate.hoursOfOperation.hours
          })

          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Successfully created template: ${this.currentTemplate.templateName}`,
            type: 'success',
            transient: true
          })

          this.currentTemplate = null
        }

        this.apiInProgress = false
        this.loadTemplates()
      } catch (err) {
        this.apiInProgress = false
        this.$store.commit(C_ADD_SITE_ALERT, {
          message: `Error in saving template: ${err && err.error && err.error.message ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async deleteTemplate (template) {
      if (this.currentTemplate && this.currentTemplate.id === template.id) {
        return alert('Cannot delete a template that is currently being edited')
      }

      const { count } = await getTotalTransportsWithHoursOfOperationTemplate(template.id)

      if (confirm(`Confirm deleting the template: ${template.templateName}? ${count ? count + ' vehicles are using it' : ''}`)) {
        try {
          this.apiInProgress = true
          await deleteStSetting(template.id)
          await this.loadTemplates()
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Successfully deleted template: ${template.templateName}`,
            type: 'success',
            transient: true
          })
          this.apiInProgress = false
        } catch (err) {
          this.apiInProgress = false
          this.$store.commit(C_ADD_SITE_ALERT, {
            message: `Error in deleting template: ${err && err.error && err.error.message ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
            type: 'error'
          })
        }
      }
    },
    cancelTemplateEdit () {
      if (this.isCurrentTemplateEdited && !confirm('Confirm closing this edit, any changes will be lost')) {
        return
      }

      this.currentTemplate = null
    }
  }
}
